.Kundendaten {

    .mainbackground {
        background-color: #ffffff;
        height: 100%;
        width: 100%;
    }

        .fixPadding {
            padding: 5rem !important;
        }
}